.site-header {
	padding: 1rem 0;
	background-color: #fff;

	.site-footer__social-icons {
		text-align: right;
		color: $silver-chalice;
		i {
			border-radius: 50%;
			color: #fff;
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}
	.site-logo {
		display: inline-block;
	}
}

/*=======================================
=            header contacts            =
=======================================*/

.header-contacts {
	text-align: right;
	font-size: 0;
	a {
		color: $black-thunder;
		font-weight: bold;
		font-size: 1.5rem;
		&:first-child {
			padding-right: 1rem;
			border-right: 1px solid $silver;
			margin-right: 1rem;
		}
	}
	i {
		color: $brand-primary;
	}
}

/*=====  End of header contacts  ======*/

/*==============================
=            Navbar            =
==============================*/
.nav-strip {
	background-color: $brand-tertiary;
}
.navbar-nav {
	position: relative;
	width: 100%;
	li:first-child {
   a {
   	padding-left: 0 !important;
   }
	}
	.nav-item:last-child {
		position: absolute;
		right: 0;
		a,
		a:hover {
			background-color: $brand-primary;
			color: #fff !important;
		}
	}
}
.nav-link,
.nav-link:hover {
	text-transform: uppercase;
	font-weight: bold;
}
.navbar-toggler {
	padding-left: 0;
	border: 0;
	outline: 0 !important;
	svg {
		height: 2rem;
		stroke: #fff;
	}
}
/*=====  End of navbar  ======*/


@media screen and (max-width: 991px) {
	.site-header,
	.header-contacts,
	.site-header .site-footer__social-icons {
		text-align: center;
	}
	.site-logo {
		margin-bottom: 1rem;
	}
}
@media screen and (max-width: 767px) {
	.navbar-nav > .nav-item > a {
		padding-left: 0 !important;
		padding-top: 0 !important;
	}
	.navbar-nav > .nav-item:last-child {
		display: none;
	}
}
@media screen and (max-width: 575px) {
	.header-contacts a {
		border: 0 !important;
		padding: 0;
		display: block;
		margin: 0;
	}
}


