/*====================================
=            contact page            =
====================================*/

#map {
	min-height: 400px;
}
.send-message {
	padding: 4rem 0;
}
.get-in-touch {
	padding-top: 4rem;
}
.contact-boxes {
	margin-top: 4rem;
}
.contact-boxes__box {
	background-color: #f3f3f3;
	padding: 2rem 0;
	color: #040405;
	i {
		color: $brand-secondary;
    width: 6rem;
    height: 6rem;
    border: 1px solid $brand-secondary;
    border-radius: 60%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
	}
	h5 {
		color: $brand-secondary;
	}
	span {
		color: $brand-secondary;
	}
	a {
		color: #040405;
		font-weight: bold;
		&:hover {
			color: $brand-secondary;
		}
	}
}
.contact-boxes__box:first-child + .contact-boxes__box {
	background-color: #e1e1e1;
}

#wpcf7-f4-o1 {
	padding-top: 2rem;
}

/*=====  End of contact page  ======*/

/*==========================================
=            activities page            =
==========================================*/

.testimonial {
	border-top: 4px solid $brand-primary;
	border-bottom: 4px solid $brand-primary;
	padding: 3rem 0;
	margin: 4rem 0;
	h2 {
		color: $black-thunder;
		margin-bottom: 1rem;
	}
	p {
		font-size: 1.5rem;
		margin-bottom: 0;
	}
}

.activity-column {
	h2,
	p {
		color: $cod-grey;
	}
	h2 {
		margin-bottom: 1rem;
	}
}

.activity-column__image {
	padding-bottom: 130%;
	margin-bottom: 1rem;
	position: relative;
	@media screen and (max-width: 575px) {
		padding-bottom: 75%;
	}
}

/*=====  End of activities page  ======*/


/*====================================
=            gallery page            =
====================================*/

.photo-gallery__image {
	position: relative;
	padding-bottom: 56.25%;
	border-right: 1px solid #fff;
	&:first-child {
		border-bottom: 1px solid #fff;
	}
}

.gallery__main-content {
	p:last-child {
		margin-bottom: 0;
	}
}

/*=====  End of gallery page  ======*/

/*=================================
=            home page            =
=================================*/

.get-in-touch--home {
	padding-bottom: 20rem;
	background-color: #fcfcfa;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	background-size: 100% auto;

	.wpcf7 a {
		color: #000;
		font-weight: bold;
		font-size: 1.5rem;	
		i {
			color: $brand-primary;
			position: relative;
			top: 0.7rem;
		}
	}
}

/*=====  End of home page  ======*/



