.site-footer {
	background-color: $brand-tertiary;
	color: $brand-primary;
	padding: 3rem 0;
	text-align: right;
	font-weight: bold;
	position: fixed;
	bottom: 0;
	z-index: -1;
	width: 100%;
}
.site-footer__nav {
	a {
		color: #fff;
		padding-right: 1rem;
		&:last-child {
			padding-right: 0;
		}
	}
}
.footer-cta {
	padding: 4rem 0;
}

.footer-cta__box {
	background-color: transparentize($brand-secondary, .3);
	padding: 4rem;
	color: #fff;
	h1 {
		text-align: right;
		display: inline-block;
		padding-right: 30px;
		border-right: 4px solid $brand-primary;
		margin-bottom: 0;
	}
	span {
		color: $brand-primary;
	}
	.btn:first-child {
		margin-bottom: 1rem;
	}
	p {
		display: inline-block;
		padding-top: 2rem;
		font-size: 1.5rem;
		font-weight: bold;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 991px) {
	.site-footer,
	.site-footer__social-icons {
		text-align: center;
	}
	.footer-cta__box h1 {
		text-align: center;
		margin: 0;
		padding: 0;
		border: 0;
		margin-bottom: 2rem;
	}

	.site-footer__social-icons i {
		margin-bottom: 1.3rem;
	}
}

@media screen and (max-width: 575px) {
	.footer-cta__box {
		padding: 2rem 1rem;
	}
}