.btn-lg,
.btn-lg:hover {
	min-width: 250px;
	text-transform: uppercase;
	font-weight: bold;
}

.btn-primary:hover {
	border-color: darken($brand-primary, 10%);
}
.btn-secondary:hover {
	border-color: darken($brand-secondary, 10%);
}