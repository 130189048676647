// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #fddc2f;
$brand-secondary:       #41475f;
$brand-tertiary:        #211c11;

$black-thunder:         #231F20;

$grey-nobel: 						#b5b5b5;
$cod-grey:              #111111;
$silver-chalice:        #a1a1a1;
$silver:                #cccccc;

// Text
$link-hover-decoration: none;
$font-size-h1: 3.5rem;

//Forms
$input-box-shadow: none;
$input-border-radius: 0;
$input-border-focus: 0;
$input-box-shadow-focus: none; 
$input-padding-x: 2rem;
$input-padding-y: 1rem;
$input-border-color: #cccccc;
$input-color-placeholder: #ccc;

//buttons
$btn-secondary-color:            #fff;
$btn-secondary-bg:               $brand-secondary;
$btn-secondary-border:           $brand-secondary;
$btn-primary-border: $brand-primary;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;
$btn-border-radius: 						 0;
$btn-border-radius-lg: 					 0;
$btn-padding-y-lg:               1rem;
$input-btn-border-width:         4px;

//navbar
$navbar-padding-x:               0;
$navbar-padding-y:               0;
$navbar-inverse-color:           #fff;
$navbar-inverse-hover-color:     $brand-primary;
$navbar-inverse-active-color:    $brand-primary;
$nav-link-padding:               1rem 2rem !important;
// $navbar-inverse-disabled-color:        rgba($white,.25) !default;
