.wrap {
	background-color: #fff;
}
/*==============================================
=            slick slider overrides            =
==============================================*/

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('/wp-content/themes/activity/dist/fonts/slick.eot');
  
  src: url('/wp-content/themes/activity/dist/fonts/slick.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/activity/dist/fonts/slick.woff') format('woff'),
  url('/wp-content/themes/activity/dist/fonts/slick.ttf') format('truetype'),
  url('/wp-content/themes/activity/dist/fonts/slick.svg#slick') format('svg');
}
.slick-loading .slick-list {
  background: #fff url('/wp-content/themes/activity/dist/images/ajax-loader.gif') center center no-repeat;
}
.slick-dots li button:before {
	font-size: 1rem;
	opacity: 0.3;
}
.slick-dots li button:before,
.slick-dots li.slick-active button:before {
	color: $brand-secondary;
}
.slick-dots {
	bottom: -2rem;
}
.slick-dots li button:before {
  content: '\f0c8';
  font-family: 'FontAwesome';
}
.slick-prev,
.slick-next {
	background: $brand-secondary;
	z-index: 5;
	width: 3rem;
	height: 3rem;
	&:before {
		content: '\f178';
	  font-family: 'FontAwesome';
	  position: relative;
	  font-size: 2rem;
	  opacity: 1;
	}
	&:hover,
	&:active,
	&:focus {
		background: $brand-secondary;
	}
} 
.slick-prev {
	left: 3rem;
	&:before {
		content: '\f177';
		left: 1.1rem;
	}
}
.slick-next {
	right: 3rem;
	&:before {
		right: 1.1rem;
	}
}


/*=====  End of slick slider overrides  ======*/

/*==================================
=            typography            =
==================================*/

a,
a:hover {
	text-transform: none;
}

h1 {
	font-weight: bold;
	text-transform: uppercase;
	@media screen and (max-width: 575px) {
		font-size: 3rem;
	}
}

/*=====  End of typography  ======*/

/*======================================
=            contact form 7            =
======================================*/

div.wpcf7-response-output {
	margin: 2rem 0;
	margin-bottom: 0;
	border-color: #f00;
}
span.wpcf7-not-valid-tip {
	text-align: left;
	top: -1rem;
	position: relative;	
}
div.wpcf7-mail-sent-ok {
	border-color: $brand-primary;
}

/*=====  End of contact form 7  ======*/

/*===================================
=            page header            =
===================================*/

.page-header {
	color: #fff;
	padding: 6rem 0;
	text-transform: uppercase;
	h1 { 
		font-size: 4rem;
		@media screen and (max-width: 575px) {
			font-size: 3rem;
		}
	}
}

/*=====  End of page header  ======*/

/*=======================================
=            Title Seperator            =
=======================================*/

.title-seperator {
	position: relative;
	margin-bottom: 2rem;
	margin-top: 1.5rem;
	svg {
		fill: $brand-primary;
		position: relative;
		z-index: 1;
	}
	&:after {
		content: '';
		height: 1px;
		width: 50%;
		background-color: $grey-nobel;
		position: absolute;
		bottom: 9px;
		left: 25%;
	}

}

.title-seperator--dark:after {
	background-color: #000;
}

/*=====  End of Title Seperator  ======*/

/*====================================
=            social icons            =
====================================*/

.site-footer__social-icons {
	text-align: left;
	i {
		width: 4rem;
		height: 4rem;
		display: inline-flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		background-color: $brand-primary;
		color: $brand-tertiary;
		margin-right: 2rem;
		&:before {
			font-size: 2.2rem;
		}
	}
	a:last-child i {
		margin-right: 0;
	}
}

/*=====  End of social icons  ======*/

/*=============================================
=            page section: 				            =
=============================================*/

.page-section {
	padding: 4rem 0;
}

/*=====  End of page section:  ===============*/

/*=====================================
=            image overlay            =
=====================================*/

.image-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	background-color: transparentize(#000, .3);
	opacity: 0;
	color: #fff !important;
	font-size: 1.5rem;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	&:hover {
		opacity: 1;
		animation: fadeIn 0.5s;
	}
	i {
		font-size: 2rem;
		border: 1px solid $brand-primary;
		padding: 1rem;
		border-radius: 50%;
	}
	p {
		margin-bottom: 0;
		color: #fff !important;
	}
}


/*=====  End of image overlay  ======*/

/*==========================================
=            swipebox overrides            =
==========================================*/

//swipebox overrides
#swipebox-prev,
#swipebox-next,
#swipebox-close {
  background-image: url(/wp-content/themes/activity/dist/images/icons.png);
}
#swipebox-slider .slide-loading {
  background: url(/wp-content/themes/activity/dist/images/loader.gif) no-repeat center center;
}

#swipebox-title {
  font-size: 3rem;
  padding-top: 2rem;
  line-height: normal;
}

/*=====  End of swipebox overrides  ======*/


/*=============================================
=            full two col section             =
=============================================*/
.full-two-col-section__image-background {
	padding: 4rem;
	padding-right: 2rem;
	h1 {
		color: #fff;
		max-width: 70%;
		margin-left: auto;
		border-right: 4px solid $brand-primary;
		padding-right: 2rem;
		span {
			color: $brand-primary;
		}
	}
}
.full-two-col-section__color-background {
	background-color: $brand-secondary;
	color: #fff;
	padding: 4rem;
	padding-left: 2rem;
	p {
		max-width: 70%;
		font-size: 1.5rem;
		line-height: 1.5;
		margin-bottom: 3rem;
	}
	a:last-child {
		margin-top: 1rem;
	}
}
@media screen and (max-width: 991px) {
	.full-two-col-section__color-background {
		p {
			max-width: 100%;
		}
	}
}
@media screen and (max-width: 575px) {
	.full-two-col-section__image-background {
		padding: 4rem 2rem;
		h1 {
			max-width: 100%;

		}
	}
}

/*=====  End of full two col section   ======*/

/*===============================================
=            background image holder            =
===============================================*/

.background-image-holder {
	background-position: 50%;
	background-repeat: no-repeat;
}
.background-image-holder--150 {
	height: 150px;
}
.background-image-holder--300 {
	height: 300px;
}

/*=====  End of background image holder  ======*/

/*===================================
=            main-banner            =
===================================*/
.main-banner__slide {
	padding: 6rem 0;
	@media screen and (max-width: 767px) {
		padding: 2rem 0;
	}
	
}
.main-banner__content {
	color: #fff;
	background-color: rgba(0,0,0,0.5);
	padding: 3rem;
	padding-bottom: 3.5rem;
	@media screen and (max-width: 575px) {
		padding: 2rem;
		h1 {
			font-size: 2.5rem;
		}
	}
	p {
		margin-bottom: 3rem;
	}
	.btn + .btn {
		margin-left: 2rem;
		@media screen and (max-width: 767px) {
			margin-top: 2rem;
			margin-left: 0;
		}
	}

}
@media screen and (max-width: 991px) {
	.main-banner .slick-next {
		right: 1rem;
	}
	.main-banner .slick-prev {
		left: 1rem;
	}
}
@media screen and (max-width: 575px) {
	.main-banner .slick-next,
	.main-banner .slick-prev {
		display: none !important;
	}
}

/*=====  End of main-banner  ======*/

/*==========================================================
=            parralax wrapper for sticky footer            =
==========================================================*/
	.parralax-wrapper {
		margin-bottom: 160px;
		@media screen and (max-width: 991px) {
			margin-bottom: 245px;
		}
		@media screen and (max-width: 575px) {
			margin-bottom: 293px;
		}
	}
/*=====  End of parralax wrapper for sticky footer  ======*/

/*====================================
=            font awesome            =
====================================*/

.fa-li--primary {
	color: $brand-primary;
}
.fa-li--primary + a {
	color: $brand-secondary;
}
.fa-li--secondary {
	color: $brand-secondary;


}

/*=====  End of font awesome  ======*/

/*===========================================
=            Bootstrap overrides            =
===========================================*/

@mixin button-variant($color, $background, $border) {
  $active-background: darken($background, 10%);
  $active-border: darken($border, 10%);

  color: $color;
  background-color: $background;
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  // Hover and focus styles are shared
  @include hover {
    color: $color;
    background-color: $active-background;
    border-color: $active-border;
  }
  &:focus,
  &.focus { 
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 2px rgba($border, .5);
    } @else {
      box-shadow: 0 0 0 2px rgba($border, .5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color;
    background-color: $active-background;
    background-image: none; // Remove the gradient for the pressed/active state
    border-color: $active-border;
    @include box-shadow($btn-active-box-shadow);
  }
}

/*=====  End of Bootstrap overrides  ======*/














	

