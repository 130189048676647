.single-activity {
	.fa-ul {
		font-size: 1.2rem;
	}
}
.product-information {
	h2 {
		text-transform: uppercase;
		font-weight: bold;
	}
	.btn {
		margin-bottom: 1rem;
		margin-top: 0.5rem;
	}
}

/*=============================================
=            product image gallery            =
=============================================*/
.product-gallery {
	margin-bottom: 3.5rem !important;
}
.product-gallery__image {
	padding-bottom: 75%;
}

/*=====  End of product image gallery  ======*/

/*========================================
=            product category            =
========================================*/
.product-category {
	padding-top: 6rem;
}
.product-category__product {
	position: relative;
	color: #fff;
	padding: 1rem;
	padding-top: 50%;
	margin-bottom: 2rem;
}

.product-category__excerpt {
	p {
		margin-bottom: 0;
		line-height: 1;
	}
}

/*=====  End of product category  ======*/
